import "./App.css";
import React from "react";
import Navbar from "./components/Navbar";
import MobileNav from "./components/MobileNav";
import About from "./components/About";
import { SectionContainer } from "./AppElements";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import ContactInfo from "./components/ContactInfo";
import Home from "./components/Home";

function App() {
  return (
    <>
      <MobileNav />
      <Navbar />
      <SectionContainer isDark={true} id={"home"}>
        <Home />
      </SectionContainer>
      <SectionContainer id={"about"}>
        <About />
      </SectionContainer>
      <SectionContainer isDark={true} id={"skills"}>
        <Skills />
      </SectionContainer>
      <SectionContainer id={"projects"}>
        <Projects />
      </SectionContainer>
      <SectionContainer isDark={true} id={"contact"}>
        <Contact />
      </SectionContainer>
      <SectionContainer>
        <ContactInfo />
      </SectionContainer>
    </>
  );
}

export default App;
