import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const BackgroundContainer = styled.header`
  width: 100%;
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: scaleX(-1);
  opacity: 0.5;
  display: ${({ isMobile }) => (isMobile ? "none" : "inline")};

  @media screen and (max-width: 1024px) {
    display: ${({ isMobile }) => (isMobile ? "inline" : "none")};
  }

  @media screen and (max-width: 768px) {
    height: 93vh;
  }
`;

export const HeroText = styled.section`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    top: 20%;
  }
`;

export const HeroHeader = styled.h1`
  font-size: 6rem;
  font-weight: 300;
  font-family: "Bree Serif", serif;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media screen and (max-width: 425px) {
    font-size: 2rem;
  }

  @media (min-width: 426px) and (max-width: 650px) {
    font-size: 4rem;
  }

  @media (min-width: 651px) and (max-width: 1024px) {
    font-size: 5rem;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const HeroSubheader = styled.h2`
  font-size: 2.5rem;
  padding: 20px 0;
  font-weight: 500;
  color: #111;

  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media screen and (max-width: 425px) {
    font-size: 1.2rem;
  }

  @media (min-width: 426px) and (max-width: 650px) {
    font-size: 1.5rem;
  }

  @media (min-width: 651px) and (max-width: 1024px) {
    font-size: 2rem;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const HeroFadeText = styled.span`
  color: #eb9800;

  @media screen and (max-width: 425px) {
    display: block;
  }
`;

export const LearnMoreBtn = styled.button`
  padding: 14px 10px 14px 8px;
  border-radius: 5px;
  background-color: #eb9800;
  border-style: none;
  font-weight: bold;
  color: black;
  font-size: 1.5rem;
  text-align: center;

  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @media screen and (max-width: 425px) {
    font-size: 0.8rem;
  }

  @media (min-width: 426px) and (max-width: 1024px) {
    font-size: 1rem;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const AboutLink = styled(LinkS)`
  width: 100%;
`;

export const DownContainer = styled.div`
  position: absolute;
  color: white;
  bottom: 5%;
  left: 50%;
  font-size: 3rem;
  transform: translate(-50%, -50%);
  opacity: 0.5;

  animation: fadeArrow ease 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;

  @keyframes fadeArrow {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;
