import React from "react";
import { GrMail } from "react-icons/gr";
import { FaMap, FaPhone } from "react-icons/fa";
import {
  InfoContainer,
  ContactSection,
  HeaderContainer,
  InfoHeader,
  IconContainer,
} from "./ContactInfoElement";

function ContactInfo() {
  return (
    <>
      <InfoContainer>
        <ContactSection>
          <HeaderContainer>
            <IconContainer>
              <FaMap />
            </IconContainer>
            <InfoHeader>1738 Meadows Ave, Pittsburg, CA 94565</InfoHeader>
          </HeaderContainer>
        </ContactSection>
        <ContactSection>
          <HeaderContainer>
            <IconContainer>
              <FaPhone />
            </IconContainer>
            <InfoHeader>925.695.6652</InfoHeader>
          </HeaderContainer>
        </ContactSection>
        <ContactSection>
          <HeaderContainer>
            <IconContainer>
              <GrMail />
            </IconContainer>
            <InfoHeader>kevyneherrera@gmail.com</InfoHeader>
          </HeaderContainer>
        </ContactSection>
      </InfoContainer>
    </>
  );
}

export default ContactInfo;
