import React from "react";
import {
  SkillCard,
  SkillDescription,
  SkillHeader,
  SkillImg,
  SkillsContainer,
  SkillText,
  SkillTitle,
  SkillTitleContainer,
  Divider,
} from "./SkillsElements";

function Skills() {
  return (
    <>
      <SkillsContainer>
        <SkillTitleContainer>
          <SkillTitle>Skills</SkillTitle>
          <SkillText>
            As a software developer, I am constantly working on my expertise and
            learning how to be a more ingenious problem solver. Some of the
            software tools that I use regularly are Ruby on Rails, Django,
            Javascript, HTML, CSS, and Bootstrap. Here is a highlight of some of
            the skills I bring to any project. Take a look at the projects
            section for samples of my work using these tools.
          </SkillText>
          <Divider />
        </SkillTitleContainer>
        <SkillCard>
          <SkillImg src={require("../../images/rails.png").default} />
          <SkillHeader>Ruby on Rails Backend</SkillHeader>
          <SkillDescription>
            I use Ruby on Rails frequently to efficiently and rapidly bring
            ideas from concept to production. In conjunction with ERB or HAML, I
            have made web pages with a well designed UI and reliable backend.
          </SkillDescription>
        </SkillCard>
        <SkillCard>
          <SkillImg src={require("../../images/react.png").default} />
          <SkillHeader>React Frontend</SkillHeader>
          <SkillDescription>
            React is used to make web pages more responsive and add complexity
            to a UI. I incorporate React into a project to enhance the user
            experience and add asynchronous functionality.
          </SkillDescription>
        </SkillCard>
        <SkillCard>
          <SkillImg src={require("../../images/front-end.png").default} />
          <SkillHeader>Frontend Development</SkillHeader>
          <SkillDescription>
            HTML, CSS, and Javascript form the structure of every webpage. I use
            them to make an attractive frontend for each design.
          </SkillDescription>
        </SkillCard>
        <SkillCard>
          <SkillImg src={require("../../images/python.png").default} />
          <SkillHeader>Django Backend</SkillHeader>
          <SkillDescription>
            As an alternative to frameworks such as Ruby on Rails, I use Django
            to develop reliable webapps that take advantage of the multitudes of
            packages that the Python language has to offer.
          </SkillDescription>
        </SkillCard>
        <SkillCard>
          <SkillImg src={require("../../images/database.png").default} />
          <SkillHeader>Database Development</SkillHeader>
          <SkillDescription>
            Data persistence is necessary for any project in today’s age. I
            utilize my knowledge of database queries and development to optimize
            data storage efficiency on any project.
          </SkillDescription>
        </SkillCard>
        <SkillCard>
          <SkillImg src={require("../../images/bootstrap.png").default} />
          <SkillHeader>Bootstrap Design</SkillHeader>
          <SkillDescription>
            A webpage’s presentation is one of its most important aspects. I use
            Bootstrap to implement sleek UI designs while also developing them
            in a time efficient manner.
          </SkillDescription>
        </SkillCard>
      </SkillsContainer>
    </>
  );
}

export default Skills;
