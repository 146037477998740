import React, { useState } from "react";
import { scroller } from "react-scroll";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import {
  ExitIcon,
  MobileIconContainer,
  MobileIcon,
  MobileLogo,
  ColorLogo,
  MobileContainer,
  MobileWrapper,
  MobileMenu,
  MobileLink,
  MobileDivider,
  MobileSocialLink,
} from "../MobileNav/MobileNavElements";

function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function closeAndScroll(elementName) {
    isOpen && toggle();
    scroller.scrollTo(elementName, {
      smooth: true,
      duration: 500,
      spy: true,
      offset: -60,
    });
  }

  return (
    <>
      <MobileContainer isOpen={isOpen}>
        <MobileLogo>
          <ColorLogo>K</ColorLogo>H
        </MobileLogo>
        <ExitIcon>
          <ImCross onClick={toggle} />
        </ExitIcon>
        <MobileWrapper>
          <MobileMenu>
            <MobileLink to="#" onClick={() => closeAndScroll("home")}>
              Home
            </MobileLink>
            <MobileLink to="#" onClick={() => closeAndScroll("about")}>
              About
            </MobileLink>
            <MobileLink to="#" onClick={() => closeAndScroll("skills")}>
              Skills
            </MobileLink>
            <MobileLink to="#" onClick={() => closeAndScroll("projects")}>
              Projects
            </MobileLink>
            <MobileLink to="#" onClick={() => closeAndScroll("contact")}>
              Contact
            </MobileLink>
            <MobileDivider />
            <MobileSocialLink href="https://www.linkedin.com/in/kevyneherrera/">
              <AiFillLinkedin />
              LinkedIn
            </MobileSocialLink>
            <MobileSocialLink href="https://github.com/kherrera1738">
              <AiFillGithub />
              Github
            </MobileSocialLink>
          </MobileMenu>
        </MobileWrapper>
      </MobileContainer>
      <MobileIconContainer isOpen={isOpen}>
        <MobileLogo>
          <ColorLogo>K</ColorLogo>H
        </MobileLogo>
        <MobileIcon>
          <FaBars onClick={toggle} />
        </MobileIcon>
      </MobileIconContainer>
    </>
  );
}

export default MobileNav;
