import styled from "styled-components";

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  min-height: 50vh;
  width: 100%;
  justify-self: center;
  background-color: black;
  padding: 30px 0;

  @media screen and (max-width: 1024px) {
    margin-left: 7%;
    margin-right: 7%;
    grid-template-columns: 1fr;
  }
`;

export const ContactSection = styled.div`
  display: grid;
  width: 100%;
  align-content: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  @media screen and (max-width: 1024px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 0.9rem;
  }
`;

export const InfoHeader = styled.h3`
  font-weight: 300;
`;

export const IconContainer = styled.div`
  font-size: 5rem;
  color: #eb9800;

  @media screen and (max-width: 1024px) {
    font-size: 3.5rem;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 4rem;
  }
`;
