import styled from "styled-components";

export const SkillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-gap: 30px;
  max-width: 1440px;
  background-color: #222;
  justify-content: space-evenly;
  justify-items: center;
  align-content: center;
  justify-self: center;
  min-height: 100vh;
  padding: 10px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Divider = styled.hr`
  grid-column: 1 / -1;
  border: 1px solid #c4c4c4;
  margin: 20px 0;
`;

export const SkillTitleContainer = styled.div`
  grid-column: 1 / -1;
`;

export const SkillTitle = styled.h1`
  margin-bottom: 20px;
  color: #eb9800;
`;

export const SkillText = styled.p`
  color: white;
`;

export const SkillCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  color: white;
  padding: 10px;
  justify-items: center;
  align-content: center;
  background-color: #333;
`;

export const SkillImg = styled.img`
  max-height: 150px;
`;

export const SkillHeader = styled.h3`
  margin-bottom: 10px;
`;

export const SkillDescription = styled.p`
  text-align: center;
  font-size: 0.9rem;
`;
