import React from "react";
import {
  ProjectsContainer,
  ProjectTitleContainer,
  ProjectTitle,
  ProjectText,
  Divider,
  Card,
  CardContainer,
  HabTitle,
  BugTitle,
  BugColorTitle,
  ChorTitle,
  ProjectCard,
  ProjectLink,
  LinkContainer,
} from "./ProjectsElements";

function Projects() {
  return (
    <>
      <ProjectsContainer>
        <ProjectTitleContainer>
          <ProjectTitle>Projects</ProjectTitle>
          <ProjectText>
            Here is a showcase of some of the most recent projects that I have
            built from the ground up. These projects demonstrate my abilities in
            frontend, backend, and database development. These are being updated
            and improved regularly with more features to be added down the line.
            Please contact me if you would like a walkthrough of my code or take
            a look at my github to view the full code.
          </ProjectText>
          <Divider />
        </ProjectTitleContainer>
        <Card>
          <CardContainer className="card">
            <ProjectCard bgColor={"#343a40"} className="main">
              <HabTitle>Habit Tracker</HabTitle>
            </ProjectCard>
            <LinkContainer className="link">
              <ProjectLink href="http://hab-tracker.herokuapp.com/">
                View Project
              </ProjectLink>
            </LinkContainer>
          </CardContainer>
          <ProjectText isPadded={true}>
            A web app built using Django with a Bootstrap frontend that allows a
            user to define a habit that they want to keep track of and log
            associated data. The UI allows users to make real time asynchronous
            queries with javascript. The queried data is represented on a data
            graph for easier visualization.
          </ProjectText>
        </Card>
        <Card>
          <CardContainer className="card">
            <ProjectCard bgColor={"#212529"} className="main">
              <BugTitle>
                <BugColorTitle>Bug</BugColorTitle>
                Smacker
              </BugTitle>
            </ProjectCard>
            <LinkContainer className="link">
              <ProjectLink href="http://bug-smacker.herokuapp.com/">
                View Project
              </ProjectLink>
            </LinkContainer>
          </CardContainer>
          <ProjectText isPadded={true}>
            An issue and feature ticketing system that lets users create groups
            and keep track of ticket progress. It uses a Ruby on Rails backend
            and React frontend to make the webpage more responsive. Javascript
            is used for data visualization and a Postgres database is used for
            data persistence.
          </ProjectText>
        </Card>
        <Card>
          <CardContainer className="card">
            <ProjectCard bgColor={"#209cee"} className="main">
              <ChorTitle>Chorus</ChorTitle>
            </ProjectCard>
            <LinkContainer className="link">
              <ProjectLink href="http://chorus-store.herokuapp.com/">
                View Project
              </ProjectLink>
            </LinkContainer>
          </CardContainer>
          <ProjectText isPadded={true}>
            An instrument bidding/marketplace app where users can view new and
            used instruments sold by other musicians. This project uses a Ruby
            on Rails backend and Bootstrap for a responsive frontend. While
            using a Postgres database, this project utilizes an Amazon S3 bucket
            for image storage to improve data scalability.
          </ProjectText>
        </Card>
      </ProjectsContainer>
    </>
  );
}

export default Projects;
