import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.div`
  height: 100%;
  position: fixed;
  display: flex;
  background-color: #333333;
  flex-direction: column;
`;

export const NavContainer = styled.div`
  z-index: 10;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavHeading = styled.h1`
  display: flex;
  color: white;
  font-size: 1.8rem;
  margin: 20px 0;
  justify-content: center;

  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    font-size: 1.5rem;
  }

  @media screen and (max-height: 650px) {
    margin: 10px 0;
  }
`;

export const NavSubtitle = styled.h3`
  justify-content: center;
  display: flex;
  font-size: 1.2rem;
  margin: 0 0 20px 0;
  color: #eb9800;

  @media screen and (max-width: 1440px), screen and (max-height: 850px) {
    font-size: 1rem;
  }

  @media screen and (max-height: 650px) {
    margin: 0 0 10px 0;
  }
`;

export const HeadImg = styled.img`
  max-height: 300px;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  padding: 10px;
  margin: 10px;

  @media screen and (max-width: 1440px) {
    max-width: 100%;
    height: 225px;
  }

  @media screen and (max-height: 750px) and (min-width: 1440px) {
    height: 225px;
    margin: 10px 50px;
  }

  @media (min-height: 600px) and (max-height: 850px) and (min-width: 1441px) {
    height: 250px;
    margin-right: 40px;
    margin-left: 40px;
  }
`;

export const NavDividerLine = styled.hr`
  border: 2px solid #bdbdbd;
  width: 84%;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  color: #bdbdbd;
  flex-direction: column;
`;

export const NavItem = styled.li`
  height: 60px;
  font-size: 1.1rem;
  width: 84%;

  @media screen and (max-height: 850px) {
    font-size: 1rem;
    height: 40px;
  }
`;

export const NavLink = styled(LinkS)`
  color: #bdbdbd;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  font-weight: bold;

  &:hover {
    color: #eb9800;
    background-color: #545454;
    transition: all 0.1s ease-in-out;
  }

  &.active {
    color: #eb9800;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  text-decoration: none;
  list-style: none;
  color: #fff;
  cursor: pointer;
  margin: 10px 0;

  &:visited {
    color: #bdbdbd;
  }

  @media screen and (max-height: 850px) {
    font-size: 1.1rem;
    margin: 5px 0;
  }
`;
