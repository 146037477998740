import React from "react";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import {
  Nav,
  NavHeading,
  HeadImg,
  NavDividerLine,
  NavSubtitle,
  NavMenu,
  NavItem,
  NavLink,
  NavContainer,
  SocialLink,
} from "./NavbarElements";

function Navbar() {
  return (
    <>
      <Nav>
        <NavContainer>
          <NavMenu>
            <NavHeading>Kevyn Herrera</NavHeading>
            <NavSubtitle>Software Developer</NavSubtitle>
            <HeadImg
              src={require("../../images/me_head.png").default}
              alt="Me"
            />
            <NavDividerLine />
            <NavItem>
              <NavLink
                to="home"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="skills"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
              >
                Skills
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="projects"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
              >
                Projects
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                activeClass="active"
              >
                Contact
              </NavLink>
            </NavItem>
            <NavDividerLine />
            <SocialLink href="https://www.linkedin.com/in/kevyneherrera/">
              <AiFillLinkedin />
              LinkedIn
            </SocialLink>
            <SocialLink href="https://github.com/kherrera1738">
              <AiFillGithub />
              Github
            </SocialLink>
          </NavMenu>
        </NavContainer>
      </Nav>
    </>
  );
}

export default Navbar;
