import styled from "styled-components";

export const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
  grid-row-gap: 10px;
  min-height: 50vh;
  width: 100%;
  justify-self: center;

  @media screen and (max-width: 1024px) {
    margin-left: 7%;
    margin-right: 7%;
    grid-template-columns: 1fr;
  }
`;

export const ContactLabel = styled.div`
  display: grid;
  width: 100%;
  align-content: center;
  justify-content: center;
  color: white;
  text-align: center;

  @media screen and (max-width: 1024px) {
    margin: 20px 0;
  }
`;

export const HeaderContainer = styled.div``;

export const ContactHeader = styled.h1`
  font-weight: 300;

  @media screen and (max-width: 1440px) {
    font-size: 1.3rem;
  }
`;

export const ContactFormContainer = styled.div`
  background-color: #333;
  display: grid;
  align-content: center;
  grid-template-columns: 95%;
  justify-content: center;
`;

export const SubtitleContainer = styled.div`
  margin: 20px 0;
`;

export const ContactSubtitle = styled.h3`
  color: #eb9800;
  font-weight: 300;
  margin-left: 10px;
`;

export const ContactForm = styled.form`
  max-width: 97%;
`;

export const FormInputContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const InputContainer = styled.div`
  grid-column: ${({ isFullWidth }) => (isFullWidth ? "1 / -1" : "auto")};
`;

export const ContactInput = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 1rem;
`;

export const MessageInput = styled.textarea`
  width: 100%;
  padding: 5px;
  font-size: 1rem;
`;

export const SendBtn = styled.input`
  padding: 14px 50px;
  border-radius: 5%;
  background-color: #eb9800;
  border-style: none;
  font-weight: bold;
  color: black;
  font-size: 0.9rem;
  text-align: start;
  margin-bottom: 20px;

  &:active,
  &:focus {
    color: #555;
    background-color: #d18800;
    border-style: none;
  }
`;

export const AlertMsg = styled.p`
  color: ${({ isBad }) => (isBad ? "red" : "green")};
  margin: 5px 10px;
`;
