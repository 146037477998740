import styled from "styled-components";

export const SectionContainer = styled.div`
  background-color: ${({ isDark }) => (isDark ? "#222" : "transparent")};
  grid-template-columns: 1fr;
  display: grid;
  justify-content: center;
  margin-left: 320px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    margin-left: 245px;
  }
`;
