import styled from "styled-components";

export const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-gap: 30px;
  max-width: 1440px;
  justify-content: space-evenly;
  justify-items: center;
  align-content: center;
  justify-self: center;
  min-height: 100vh;
  padding: 10px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr;
  }
`;

export const Divider = styled.hr`
  grid-column: 1 / -1;
  border: 1px solid #c4c4c4;
  margin: 20px 0;
`;

export const ProjectTitleContainer = styled.div`
  grid-column: 1 / -1;
`;

export const ProjectTitle = styled.h1`
  margin-bottom: 20px;
  color: #333;
`;

export const ProjectText = styled.p`
  padding: ${({ isPadded }) => (isPadded ? "10px" : 0)};
`;

export const CardContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ProjectCard = styled.div`
  height: 35vh;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  display: grid;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  position: relative;
  text-align: center;
`;

export const HabTitle = styled.h1`
  color: white;
  font-family: lobster;
  font-size: 3rem;
`;

export const BugTitle = styled.h1`
  color: white;
  font-size: 2rem;
  font-family: verdana, arial, helvetica, sans-serif;
`;

export const BugColorTitle = styled.span`
  background: -webkit-linear-gradient(
    rgba(0, 105, 128, 1),
    rgba(178, 253, 255, 1)
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
`;

export const ChorTitle = styled.h1`
  color: black;
  font-size: 3rem;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const LinkContainer = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.4s ease-in;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 1%;
  border: 1px solid #88a096;
  @media (min-width: 1025px) and (max-width: 1440px) {
    grid-template-columns: 400px auto;
    display: grid;
  }
`;

export const ProjectLink = styled.a`
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;

  &:visited {
    color: black;
  }
`;
