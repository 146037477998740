import React from "react";
import {
  AboutHeader,
  AboutContainer,
  ImageContainer,
  Img,
  Divider,
  AboutContent,
  AboutDescription,
  DescriptionHeader,
  DescriptionSubtitle,
  InfoContainer,
  Info,
  ResumeBtn,
  WorkBtn,
  WorkLink,
  ResumeLink,
} from "./AboutElements";

function About() {
  return (
    <AboutContainer>
      <AboutHeader>About Me</AboutHeader>
      <Divider />
      <ImageContainer>
        <Img src={require("../../images/me.png").default} />
      </ImageContainer>
      <AboutContent>
        <DescriptionSubtitle>Who am I?</DescriptionSubtitle>
        <DescriptionHeader>
          I am Kevyn Herrera, a Fullstack Web Developer
        </DescriptionHeader>
        <AboutDescription>
          I am a developer based in the San Francisco Bay Area with an eagerness
          to take on any engineering challenge and bringing it to life. Growing
          up, I have always had a passion for projects involving technical
          problems which led to my involvement in areas such as robotics,
          manufacturing, and software development. After graduating from UC
          Berkeley with a degree in Electrical Engineering and Computer Science,
          I went on to use my hardware and software skills in an industry
          setting where I have been the lead engineer on projects such as the
          Jim Dunlop CBJ95. Take a look at my portfolio and feel free to get in
          touch if you want to work together.
        </AboutDescription>
        <Divider />
        <InfoContainer>
          <Info>From: California, US</Info>
          <Info>Email: kevyneherrera@gmail.com</Info>
          <ResumeLink
            href="https://kh-personal-web.s3-us-west-1.amazonaws.com/KevynHerreraResume.pdf"
            download
          >
            <ResumeBtn>Download Resume</ResumeBtn>
          </ResumeLink>
          <WorkLink
            to="projects"
            smooth={true}
            duration={500}
            spy={true}
            activeClass="active"
            offset={-60}
          >
            <WorkBtn>View Work</WorkBtn>
          </WorkLink>
        </InfoContainer>
      </AboutContent>
    </AboutContainer>
  );
}

export default About;
