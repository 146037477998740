import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const MobileIconContainer = styled.nav`
  background-color: #333;
  height: 7vh;
  min-height: 60px;
  width: 100%;
  z-index: 10;
  display: none;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    display: block;
    position: sticky;
    top: 0;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }
`;

export const MobileContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #333;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "100%")};
`;

export const ExitIcon = styled.div`
  height: 60px;
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
`;

export const MobileLogo = styled.h1`
  font-size: 2rem;
  position: absolute;
  color: white;
  top: 10px;
  left: 12px;
`;

export const ColorLogo = styled.span`
  color: #eb9800;
`;

export const MobileWrapper = styled.div`
  color: white;
`;

export const MobileMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 768px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const MobileLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #eb9800;
    transition: 0.2s ease-in-out;
  }
`;

export const MobileSocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;
  margin: 10px 0;

  &:hover {
    color: #eb9800;
    transition: 0.2s ease-in-out;
  }
`;

export const MobileDivider = styled.hr`
  width: 86%;
  justify-self: center;
  margin: 10px 0;
  max-height: 0px;
`;
