import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 50%;
  grid-row-gap: 10px;
  max-width: 1440px;
  min-height: 80vh;
  align-content: center;
  justify-content: center;
  justify-self: center;

  @media screen and (max-width: 1024px) {
    margin-left: 7%;
    margin-right: 7%;
    grid-template-columns: 1fr;
  }
`;

export const AboutHeader = styled.h1`
  margin-left: 10px;
  margin-top: 20px;
  font-size: 2rem;
  grid-column: 1 / -1;
  color: #333333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

export const Divider = styled.hr`
  grid-column: 1 / -1;
  border: 1px solid #c4c4c4;
  margin-bottom: 20px;
  max-height: 0;
`;

export const ImageContainer = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;

export const Img = styled.img`
  padding: 3px;
  margin: 0 12px;
  border: 1px solid #000;

  @media screen and (max-width: 425px) {
    width: 80%;
    height: auto;
  }
`;

export const AboutContent = styled.div`
  display: grid;
  align-content: center;
`;

export const DescriptionHeader = styled.h1`
  margin: 10px 0;
`;

export const DescriptionSubtitle = styled.h3`
  color: #eb9800;
`;

export const AboutDescription = styled.p`
  grid-column: 1 / -1;
  font-size: 1rem;
  margin-bottom: 20px;
`;

export const InfoContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
  margin: 20px 0;
  column-gap: 30px;

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const Info = styled.p`
  color: #333;
  font-size: 0.9rem;

  @media screen and (max-width: 425px) {
    margin: 10px 0;
  }
`;

export const ResumeLink = styled.a``;

export const ResumeBtn = styled.button`
  margin-top: 20px;
  margin-right: 20px;
  padding: 14px 10px 14px 8px;
  border-radius: 5px;
  background-color: #eb9800;
  border-style: none;
  font-weight: bold;
  color: black;
  font-size: 0.9rem;
  text-align: start;
  width: 100%;

  &:active,
  &:focus {
    background-color: #d18800;
    border-style: none;
  }
`;

export const WorkBtn = styled.button`
  margin-top: 20px;
  margin-right: 20px;
  padding: 14px 10px 14px 8px;
  border-radius: 5px;
  background-color: #88a096;
  border-style: none;
  font-weight: bold;
  color: black;
  font-size: 0.9rem;
  text-align: start;
  width: 100%;

  &:active,
  &:focus {
    color: black;
    background-color: #88a096;
    border-style: none;
  }
`;

export const WorkLink = styled(LinkS)`
  width: 100%;
`;
