import React from "react";
import {
  BackgroundContainer,
  BackgroundImage,
  HeroFadeText,
  HeroHeader,
  HeroSubheader,
  HeroText,
  LearnMoreBtn,
  AboutLink,
  DownContainer,
} from "./HomeComponents";
import { ImArrowDown } from "react-icons/im";

function Home() {
  return (
    <>
      <BackgroundContainer>
        <BackgroundImage
          src={require("../../images/me_background.png").default}
        />
        <BackgroundImage
          src={require("../../images/me_back_mobile.png").default}
          isMobile={true}
        />
        <HeroText>
          <HeroHeader>Kevyn Herrera</HeroHeader>
          <HeroSubheader>
            I am a <HeroFadeText>Fullstack Developer</HeroFadeText>{" "}
          </HeroSubheader>
          <AboutLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            activeClass="active"
            offset={-60}
          >
            <LearnMoreBtn>Learn More</LearnMoreBtn>
          </AboutLink>
        </HeroText>
        <DownContainer>
          <ImArrowDown />
        </DownContainer>
      </BackgroundContainer>
    </>
  );
}

export default Home;
