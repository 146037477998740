import React, { useRef } from "react";
import emailjs from "emailjs-com";
import {
  ContactContainer,
  ContactForm,
  ContactFormContainer,
  ContactHeader,
  ContactLabel,
  ContactSubtitle,
  ContactInput,
  SubtitleContainer,
  InputContainer,
  FormInputContainer,
  MessageInput,
  SendBtn,
  HeaderContainer,
  AlertMsg,
} from "./ContactElements";

function Contact() {
  const nameInput = useRef(null);
  const emailInput = useRef(null);
  const subjectInput = useRef(null);
  const messageInput = useRef(null);
  const errorMessage = useRef(null);
  const successMessage = useRef(null);

  function clearAlerts() {
    errorMessage.current.innerText = null;
    successMessage.current.innerText = null;
  }

  function allValuesFilled() {
    return (
      nameInput.current.value &&
      emailInput.current.value &&
      subjectInput.current.value &&
      messageInput.current.value
    );
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function clearForm() {
    nameInput.current.value = null;
    emailInput.current.value = null;
    subjectInput.current.value = null;
    messageInput.current.value = null;
  }

  function sendMail(e) {
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          successMessage.current.innerText = "Message sent successfully.";
        },
        (error) => {
          console.log(error);
          errorMessage.current.innerText =
            "Seems like there was an issue. Try again later.";
        }
      );
  }

  function sendMessage(e) {
    e.preventDefault();

    clearAlerts();

    if (!allValuesFilled()) {
      errorMessage.current.innerText =
        "* Make sure to fill out all parts of the form.";
    } else if (!validateEmail(emailInput.current.value)) {
      errorMessage.current.innerText = "* Please use a valid email.";
    } else {
      sendMail(e);
    }
    clearForm();
  }

  return (
    <>
      <ContactContainer>
        <ContactLabel>
          <HeaderContainer>
            <ContactHeader>Want to get in touch?</ContactHeader>
          </HeaderContainer>
        </ContactLabel>
        <ContactFormContainer>
          <SubtitleContainer>
            <ContactSubtitle>Feel free to send me a message</ContactSubtitle>
            <AlertMsg ref={errorMessage} isBad={true}></AlertMsg>
            <AlertMsg ref={successMessage}></AlertMsg>
          </SubtitleContainer>
          <ContactForm id={"messageForm"} onSubmit={sendMessage}>
            <FormInputContainer>
              <InputContainer>
                <ContactInput
                  placeholder="Name"
                  ref={nameInput}
                  name={"name"}
                />
              </InputContainer>
              <InputContainer>
                <ContactInput
                  placeholder="Email"
                  ref={emailInput}
                  name={"email"}
                />
              </InputContainer>
              <InputContainer isFullWidth={true}>
                <ContactInput
                  placeholder="Subject"
                  ref={subjectInput}
                  name={"subject"}
                />
              </InputContainer>
              <InputContainer isFullWidth={true}>
                <MessageInput
                  placeholder="Message"
                  rows="5"
                  ref={messageInput}
                  name={"message"}
                />
              </InputContainer>
              <InputContainer isFullWidth={true}>
                <SendBtn type={"submit"} form={"messageForm"} value={"Send"} />
              </InputContainer>
            </FormInputContainer>
          </ContactForm>
        </ContactFormContainer>
      </ContactContainer>
    </>
  );
}

export default Contact;
